<template>
  <v-container class="pt-0 px-0">
    <v-img
      :src="require('@/assets/settings_bg.png')"
      contain
      style="position: absolute; top: 0"
    ></v-img>
    <back-toolbar title="settings.settings"></back-toolbar>
    <v-container>
      <div style="height: 35vh">
        <v-img
          :src="require('@/assets/settings.png')"
          width="50%"
          height="auto"
          class="d-block mx-auto"
        ></v-img>
      </div>
      <v-card class="px-3 mx-3" style="border-radius: 10px" elevation="3">
        <v-list class="settings">
          <v-list-item class="px-0">
            <v-list-item-icon class="mr-2">
              <v-img
                :class="$vuetify.theme.dark ? 'invert' : ''"
                :src="require('@/assets/settings/dark.svg')"
              >
              </v-img>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="Text-Dark-Text-1---18-Left-Align mt-1">
                {{ $t("settings.dark") }}
              </v-list-item-title>
            </v-list-item-content>
            <v-switch inset v-model="dark" color="primary"></v-switch>
          </v-list-item>
          <v-list-item class="px-0">
            <v-list-item-icon class="mr-2">
              <v-img
                :class="$vuetify.theme.dark ? 'invert' : ''"
                :src="require('@/assets/settings/notification.svg')"
              ></v-img>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="Text-Dark-Text-1---18-Left-Align mt-1">
                {{ $t("settings.notifications") }}
              </v-list-item-title>
            </v-list-item-content>
            <v-switch color="primary" v-model="notifications" inset></v-switch>
          </v-list-item>
          <!-- as there is no functionality behind this, not shown
          <v-list-item class="px-0">
            <v-list-item-icon class="mr-3">
              <v-img :src="require('@/assets/settings/privacy.svg')"></v-img>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="Text-Dark-Text-1---18-Left-Align mt-1">
                {{ $t("settings.privacymode") }}
              </v-list-item-title>
            </v-list-item-content>
            <v-switch color="primary" v-model="incognito" inset></v-switch>
          </v-list-item>
          <v-list-item class="px-0">
            <v-list-item-icon class="mr-3">
              <v-img :src="require('@/assets/settings/deals.svg')"></v-img>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="Text-Dark-Text-1---18-Left-Align mt-1">
                {{ $t("settings.hotdeals") }}
              </v-list-item-title>
            </v-list-item-content>
            <v-switch color="primary" v-model="hotdeal" inset></v-switch>
          </v-list-item>-->
          <v-list-item class="px-0">
            <v-list-item-icon class="mr-2">
              <v-img
                :class="$vuetify.theme.dark ? 'invert' : ''"
                :src="require('@/assets/settings/language.svg')"
              ></v-img>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="Text-Dark-Text-1---18-Left-Align mt-1">
                {{ $t("settings.language") }}
              </v-list-item-title>
            </v-list-item-content>
            <language-selection
              @change-language="changeLanguage"
            ></language-selection>
          </v-list-item>
        </v-list>
      </v-card>
    </v-container>
  </v-container>
</template>

<script>
import BackToolbar from "@/components/app/common/BackToolbar";
import LanguageSelection from "@/components/app/LanguageSelection";
export default {
  components: {
    BackToolbar,
    LanguageSelection,
  },
  computed: {
    dark: {
      get() {
        return this.$store.state.settings.dark;
      },
      set(val) {
        this.$store.commit("settings/SET_DARK", val);
      },
    },
    hotdeal: {
      get() {
        return this.$store.state.settings.hotdeal;
      },
      set(val) {
        this.$store.commit("settings/SET_HOTDEAL", val);
      },
    },
    incognito: {
      get() {
        return this.$store.state.settings.incognito;
      },
      set(val) {
        this.$store.commit("settings/SET_INCOGNITO", val);
      },
    },
    notifications: {
      get() {
        return this.$store.state.settings.notifications;
      },
      set(val) {
        this.$store.commit("settings/SET_NOTIFICATIONS", val);
      },
    },
  },
  methods: {
    changeLanguage(langKey) {
      this.$store.commit("languages/SET_CURRENT_LANG", langKey);
      this.$store.dispatch("session/initHomeScreen");
    },
  },
};
</script>
<style scoped>
.settings i.v-icon.v-icon {
  width: 20px;
  height: 20px;
}
.settings .v-list-item {
  height: 50px;
}
/deep/ .settings .v-input--switch__thumb {
  color: white !important;
  width: 26px;
  margin-left: -3px;
  height: 26px;
  margin-top: -3px;
}
/deep/ .settings .v-input--switch__track {
  opacity: 1;
}
/deep/ .settings .v-input--switch .v-input--switch__track {
  color: #cacaca;
}
/deep/ .settings i.v-icon.v-icon.mdi-menu-down {
  font-size: 35px;
  color: var(--v-onbackground-base);
  opacity: 1;
}
/deep/ .settings .v-input--selection-controls__ripple::before {
  display: none;
}
</style>
