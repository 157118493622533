<template>
  <div>
    <v-select
      class="languages pt-2"
      :items="$store.state.languages.items"
      v-model="selectedLanguage"
      item-value="id"
      item-text="name"
      solo
      dense
      flat
      :menu-props="{ bottom: true, offsetY: true, left: true }"
    >
      <template v-slot:selection="data">
        {{ data.item.id.substring(0, 2) }}
      </template>
      <template v-slot:item="data">
        <v-radio-group hide-details>
          <v-radio :label="data.item.name"></v-radio>
        </v-radio-group>
      </template>
    </v-select>
  </div>
</template>
<script>
export default {
  computed: {
    selectedLanguage: {
      get() {
        return this.$store.state.languages.currentLang;
      },
      set(langKey) {
        this.$emit("change-language", langKey);
      },
    },
  },
};
</script>
<style>
.languages {
  width: 80px;
  position: absolute;
  right: 0;
  height: 50px;
  top: 0px;
}
.languages .v-select__selections {
  text-transform: uppercase;
}
.v-menu__content {
  border-radius: 10px;
}

.v-select-list .v-list-item .v-input {
  height: 30px;
  margin: 0;
}
.v-radio .v-label {
  color: var(--v-onbackground-base) !important;
}
.v-menu__content .v-list-item--active:before {
  background: transparent;
}
.v-menu__content .v-list-item i {
  margin-top: 0px;
}
.v-menu__content .v-list-item i::before {
  content: "\2B24";
  color: #cacaca;
  margin-top: -8px;
}
.v-menu__content .v-list-item.v-list-item--active i::before {
  content: "\2713";
  color: white;
  margin-top: 0px;
}
.v-menu__content .v-list-item.v-list-item--active i {
  border-radius: 100%;
  background-color: var(--v-primary-base);
}
.v-menu__content .v-list {
  background-color: var(--v-background-lighten1) !important;
}
</style>
